@import url(https://fonts.googleapis.com/css2?family=Passion+One&display=swap);

  html {
    height: -webkit-fill-available;
  }

body{
    background-color: black;
    overflow: hidden;
    -webkit-overflow-scrolling:touch;
    font-family:Passion One,cursive;
    height: 100%;
    height: 100vh;
    height: 100dvh;
    -webkit-overflow-scrolling:touch;
}



.App {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-template-rows: repeat(10, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: 100%;
    height: 100vh;
    height: 100dvh;
}

.div1 { grid-area: 1 / 9 / 3 / 11; }
.div2 { grid-area: 3 / 1 / 7 / 11; }
.div3 { grid-area: 7 / 1 / 8 / 11; }
.div4 { grid-area: 8 / 4 / 10 / 8; }
.div5 { grid-area: 10 / 4 / 11 / 8; }
.div6 { grid-area: 10 / 10 / 11 / 11; }

/*manifesto*/
.div1 {
    display: flex;
    align-items:center;
    justify-content: center;
}

.div1 img{
    cursor:pointer;
    position:absolute;
    right:20px;
    top:20px;
    width:100px
}

/* letters */
.div2 {
    color:orange;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: center;
    color:gold;
    font-size: max(20vw, 36px);
}

.div2 .name{
    border:0 solid green;
    color:gold;
    font-size:max(10vw, 36px);
    padding-top:15vh;
}

/* yes text */
.div3{
    width: 100vw;
    text-align: left;
    padding-left: 30px;
    font-size: max(3vw, 16px);
}


/* butao */
.div4{
    display: flex;
    align-items:flex-end;
    justify-content: center;
    text-align: center;
}

/* footer */
.div5{
    display: flex;
    align-items:flex-end;
    justify-content: center;
    color:#daa520
}

a:link{
    color:#daa520;
    text-decoration:underline
}
a:visited{
    color:#daa520;
    text-decoration:underline
}
a:hover{
    color:#daa520;
    text-decoration:underline
}
a:active{
    color:#daa520;
    text-decoration:underline
}

.Manifesto{
    -webkit-overflow-scrolling:touch;
    align-items:flex-start;
    background-color:#ffe54c;
    display:flex;
    height:100vh;
    justify-content:center;
    left:0;
    padding-top:20px;
    position:absolute;
    top:0;
    width:100vw
}

.inside{
    border:0 solid #000;
    font-family:Passion One,cursive;
    font-size:max(2vw, 18px);
    font-size-adjust:none;
    height:70vh;
    text-align:justify;
    text-justify:newspaper;
    width:75vw
}

.button-52{
    background-color:transparent;
    border:1px solid #000;
    color:#000;
    cursor:pointer;
    font-family:Passion One,cursive;
    font-size:16px;
    font-weight:200;
    letter-spacing:1px;
    outline:0;
    padding:13px 20px;
    position:fixed;
    position:relative;
    touch-action:manipulation;
    user-select:none;
    -webkit-user-select:none
}
.button-52:after{
    background-color:#ffe54c;
    content:"";
    height:100%;
    left:7px;
    position:absolute;
    top:7px;
    transition:.2s;
    width:100%;
    z-index:-1
}
.button-52:hover:after{
    left:0;
    top:0
}

@media screen and (orientation: landscape) and (pointer: coarse) {
    body{
        background-color: black;
    }
    .div2 {
        font-size: 10vw;
    }
    .div2 .name{
        font-size: 8vw;
    }

    button{
    }
}

p{
    color: red;
}